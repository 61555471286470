var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3ScrollableContainer",
    { staticClass: "lsb-container", attrs: { vertical: "" } },
    [
      _vm.displayType === "views"
        ? [
            _vm.mapMode === "replay2d" && _vm.vodPlayer !== "playback"
              ? _c(
                  "div",
                  { staticClass: "left-side-tabs" },
                  [
                    _c(
                      "V3BtnGroup",
                      [
                        _c(
                          "V3Button",
                          {
                            attrs: {
                              active: _vm.vodPlayer === _vm.vodPlatform
                            },
                            on: {
                              click: function($event) {
                                return _vm.setVODPlayer(_vm.vodPlatform)
                              }
                            }
                          },
                          [_vm._v(" VOD ")]
                        ),
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.vodPlayer === "vod" },
                            on: {
                              click: function($event) {
                                return _vm.setVODPlayer("vod")
                              }
                            }
                          },
                          [_vm._v(" Minimap ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm.mapMode === "replay2d" && _vm.vodPlayer === "playback"
              ? _c(
                  "div",
                  { staticClass: "left-side-tabs" },
                  [
                    _c(
                      "V3BtnGroup",
                      [
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.mapSubMode === "agents" },
                            on: {
                              click: function($event) {
                                return _vm.setReplay2DMode("agents")
                              }
                            }
                          },
                          [_vm._v(" Icons ")]
                        ),
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.mapSubMode === "traces" },
                            on: {
                              click: function($event) {
                                return _vm.setReplay2DMode("traces")
                              }
                            }
                          },
                          [_vm._v(" Traces ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm.mapMode === "analytics" && _vm.mapSubMode !== "kdzones"
              ? _c(
                  "div",
                  { staticClass: "left-side-tabs" },
                  [
                    _c(
                      "V3BtnGroup",
                      [
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.mapSubMode === "heatmap" },
                            on: {
                              click: function($event) {
                                return _vm.setAnalytics2DMode("heatmap")
                              }
                            }
                          },
                          [_vm._v(" Heatmap ")]
                        ),
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.mapSubMode === "traces" },
                            on: {
                              click: function($event) {
                                return _vm.setAnalytics2DMode("traces")
                              }
                            }
                          },
                          [_vm._v(" Traces ")]
                        ),
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.mapSubMode === "off" },
                            on: {
                              click: function($event) {
                                return _vm.setAnalytics2DMode("off")
                              }
                            }
                          },
                          [_vm._v(" None ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            !(_vm.mapMode === "replay2d" && _vm.vodPlayer !== "playback")
              ? _c(
                  "div",
                  { staticClass: "left-side-tabs" },
                  [
                    _c(
                      "V3BtnGroup",
                      [
                        _c(
                          "V3Button",
                          {
                            attrs: {
                              active: _vm.mapOptions.includes("smokes")
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleMapOptions("smokes")
                              }
                            }
                          },
                          [_vm._v(" Smokes ")]
                        ),
                        _c(
                          "V3Button",
                          {
                            attrs: { active: _vm.mapOptions.includes("walls") },
                            on: {
                              click: function($event) {
                                return _vm.toggleMapOptions("walls")
                              }
                            }
                          },
                          [_vm._v(" Walls ")]
                        ),
                        _vm.mapSubMode !== "kdzones"
                          ? _c(
                              "V3Button",
                              {
                                attrs: {
                                  active: _vm.mapOptions.includes("deaths")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleMapOptions("deaths")
                                  }
                                }
                              },
                              [_vm._v(" Deaths ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.mapMode === "replay2d" && _vm.vodPlayer === "playback"
              ? [
                  _vm.mapSubMode === "agents"
                    ? [
                        _vm._t("snapshot"),
                        _c(
                          "V3Panel",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { expanded: "", title: "hide icons" }
                          },
                          [
                            _c(
                              "V3BtnGroup",
                              [
                                _c(
                                  "V3Button",
                                  {
                                    attrs: {
                                      active: this.state.iconHide.unselected
                                    },
                                    on: {
                                      click: function() {
                                        return (this$1.state.iconHide.unselected = !this$1
                                          .state.iconHide.unselected)
                                      }
                                    }
                                  },
                                  [_vm._v(" Hide Unselected ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm._l(_vm.teams, function(team) {
                    return [
                      _c("V3Panel", {
                        key: "panel-" + team.id,
                        staticStyle: { width: "100%" },
                        attrs: {
                          expandable: true,
                          "expand-on-title-click": false,
                          expanded: _vm.teamPanelExpanded[team.id]
                        },
                        on: {
                          "update:expanded": function($event) {
                            return _vm.$set(
                              _vm.teamPanelExpanded,
                              team.id,
                              $event
                            )
                          },
                          titleClick: function($event) {
                            return _vm.togglePlaybackTeamPlayers(team)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header-text",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mvo-team-name",
                                      class: { disabled: !team.enabled }
                                    },
                                    [_vm._v(_vm._s(team.name))]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "header-icon",
                              fn: function() {
                                return [
                                  _c("V3TeamLogo", {
                                    class: { disabled: !team.enabled },
                                    attrs: {
                                      src: team.logo_url || team.image,
                                      grayscale: ""
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "V3Container",
                                    {
                                      attrs: {
                                        visible: _vm.teamPanelExpanded[team.id]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          key: "agents-" + team.id,
                                          staticClass: "agents-view-panel"
                                        },
                                        _vm._l(team.agents, function(
                                          teamAgent
                                        ) {
                                          return _c(
                                            "AgentReplayDisplayOptions",
                                            {
                                              key: teamAgent.player.id,
                                              attrs: {
                                                agent: teamAgent.agent,
                                                player: teamAgent.player,
                                                abilities: teamAgent.abilities,
                                                filters: _vm.filters,
                                                disabled: !team.enabled
                                              }
                                            }
                                          )
                                        }),
                                        1
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  })
                ]
              : _vm._e(),
            _vm.mapMode === "analytics"
              ? [
                  _vm.mapSubMode === "kdzones" ? _c("KDZoneFilter") : _vm._e(),
                  _c(
                    "V3BtnGroup",
                    { staticClass: "left-side-tabs" },
                    [
                      _vm.mapSubMode === "heatmap"
                        ? _c(
                            "V3Button",
                            {
                              attrs: { active: this.state.minimapSelector },
                              on: {
                                click: function() {
                                  return (this$1.state.minimapSelector = !this$1
                                    .state.minimapSelector)
                                }
                              }
                            },
                            [_vm._v(" Player Position % ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._l(_vm.teams, function(team) {
                    return [
                      _c("V3Panel", {
                        key: "panel-" + team.id,
                        staticStyle: { width: "100%" },
                        attrs: {
                          expandable: true,
                          "expand-on-title-click": false,
                          expanded: _vm.teamPanelExpanded[team.id]
                        },
                        on: {
                          "update:expanded": function($event) {
                            return _vm.$set(
                              _vm.teamPanelExpanded,
                              team.id,
                              $event
                            )
                          },
                          titleClick: function($event) {
                            return _vm.toggleAnalyticsTeamPlayers(team)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header-text",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mvo-team-name",
                                      class: { disabled: !team.enabled }
                                    },
                                    [_vm._v(_vm._s(team.name))]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "header-icon",
                              fn: function() {
                                return [
                                  _c("V3TeamLogo", {
                                    class: { disabled: !team.enabled },
                                    attrs: {
                                      src: team.logo_url || team.image,
                                      grayscale: ""
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "V3Container",
                                    {
                                      attrs: {
                                        visible: _vm.teamPanelExpanded[team.id]
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          key: "agents-" + team.id,
                                          staticClass: "agents-view-panel"
                                        },
                                        _vm._l(team.agents, function(
                                          teamAgent
                                        ) {
                                          return _c(
                                            "AgentAnalyticsDisplayOptions",
                                            {
                                              key: teamAgent.player.id,
                                              attrs: {
                                                agent: teamAgent.agent,
                                                player: teamAgent.player,
                                                abilities: teamAgent.abilities,
                                                filters: _vm.filters,
                                                disabled: !team.enabled
                                              }
                                            }
                                          )
                                        }),
                                        1
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  })
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }