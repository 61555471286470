var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-controller" },
    [
      _vm.loading
        ? [
            _c("MapPortal", { attrs: { to: "header-center" } }),
            _c("MapPortal", { attrs: { to: "header-right" } }),
            _c("MapPortal", { attrs: { to: "sidebar-left" } }),
            _c("MapPortal", { attrs: { to: "sidebar-right" } }),
            _c("V3MatchHeader", { attrs: { loading: "" } })
          ]
        : [
            _c(
              "MapPortal",
              { staticClass: "w-100", attrs: { to: "header-center" } },
              [_c("V3MatchHeader")],
              1
            ),
            _c("Portal", { attrs: { to: "menu" } }),
            _vm.mapToolData && _vm.mapToolEvents
              ? _c("MapTool2d", {
                  staticClass: "map-container",
                  attrs: {
                    report: _vm.report,
                    data: _vm.mapToolData,
                    "disable-replay": _vm.multiMatch,
                    events: _vm.filteredEvents,
                    notes: _vm.cleanedNotes,
                    filters: _vm.mapToolFilters,
                    "has-advanced": _vm.hasAdvanced,
                    "has-economy": _vm.hasEconomy,
                    "has-orbs": _vm.hasOrbs,
                    "has-outcome": _vm.hasOutcome,
                    "has-plants": _vm.hasPlants,
                    "has-wins": _vm.hasWins,
                    "has-vod": _vm.hasVod,
                    "has-playback-positions": _vm.hasPlaybackPositions,
                    "has-minimap-vods": _vm.hasMinimapVods,
                    "has-abilities-usage": _vm.hasAbilitiesUsage,
                    "is-scrim": _vm.isScrim,
                    "playback-rate": _vm.playbackRate,
                    playing: _vm.playing,
                    "round-duration": _vm.roundDuration,
                    "saving-bookmark": _vm.savingBookmark,
                    "selected-round-info": _vm.selectedRoundStats,
                    "supported-playback-rates": _vm.supportedPlaybackRates,
                    "take-screenshot": _vm.takeScreenshot,
                    "vod-platform":
                      _vm.singleMatch && _vm.singleMatch.vod_platform,
                    "note-tags": _vm.noteTags,
                    "ads-enabled": _vm.adsEnabled
                  },
                  on: {
                    "update:playbackRate": function($event) {
                      _vm.playbackRate = $event
                    },
                    "update:playback-rate": function($event) {
                      _vm.playbackRate = $event
                    },
                    "update:playing": function($event) {
                      _vm.playing = $event
                    },
                    loadBookmark: _vm.loadBookmark,
                    saveNote: _vm.saveNote
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.multiMatch || !_vm.selectedRound || _vm.hasVod
                        ? {
                            key: "replay2d",
                            fn: function() {
                              return [
                                _vm.singleMatch
                                  ? _c(
                                      "div",
                                      { staticStyle: { height: "100%" } },
                                      [
                                        _vm.singleRound &&
                                        _vm.singleMatch.vod_platform ===
                                          "twitch" &&
                                        _vm.singleMatch.vod_id &&
                                        _vm.vodPlayer === "twitch"
                                          ? _c("TwitchReplay", {
                                              attrs: {
                                                "current-time": _vm.currentTime,
                                                id: _vm.singleMatch.vod_id,
                                                "offset-time":
                                                  _vm.roundOffsetTime,
                                                playing: _vm.playing,
                                                "playback-rate":
                                                  _vm.playbackRate,
                                                "start-time":
                                                  _vm.selectedRoundVodStartMillis,
                                                "end-time":
                                                  _vm.selectedRoundVodStartMillis +
                                                  _vm.singleRound
                                                    .vod_duration_millis,
                                                "supported-playback-rates":
                                                  _vm.supportedPlaybackRates
                                              },
                                              on: {
                                                "update:currentTime": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:current-time": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:playing": function(
                                                  $event
                                                ) {
                                                  _vm.playing = $event
                                                },
                                                "update:playbackRate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:playback-rate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:supportedPlaybackRates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                },
                                                "update:supported-playback-rates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                }
                                              }
                                            })
                                          : _vm.singleRound &&
                                            _vm.singleMatch.vod_platform ===
                                              "youtube" &&
                                            _vm.singleMatch.vod_id &&
                                            _vm.vodPlayer === "youtube"
                                          ? _c("YoutubeReplay", {
                                              attrs: {
                                                "current-time": _vm.currentTime,
                                                id: _vm.singleMatch.vod_id,
                                                "offset-time":
                                                  _vm.roundOffsetTime,
                                                playing: _vm.playing,
                                                "playback-rate":
                                                  _vm.playbackRate,
                                                "start-time":
                                                  _vm.selectedRoundVodStartMillis,
                                                "end-time":
                                                  _vm.selectedRoundVodStartMillis +
                                                  _vm.singleRound
                                                    .vod_duration_millis,
                                                "supported-playback-rates":
                                                  _vm.supportedPlaybackRates
                                              },
                                              on: {
                                                "update:currentTime": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:current-time": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:playing": function(
                                                  $event
                                                ) {
                                                  _vm.playing = $event
                                                },
                                                "update:playbackRate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:playback-rate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:supportedPlaybackRates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                },
                                                "update:supported-playback-rates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                }
                                              }
                                            })
                                          : _vm.singleRound &&
                                            _vm.singleMatch.vod_platform ===
                                              "augment" &&
                                            _vm.vodPlayer === "augment" &&
                                            _vm.singleRound.vod_broadcast_url
                                          ? _c("Replay2D", {
                                              attrs: {
                                                "control-bar": false,
                                                "current-time": _vm.currentTime,
                                                "round-replay-url":
                                                  _vm.singleRound
                                                    .vod_broadcast_url,
                                                "offset-time":
                                                  _vm.roundOffsetTime,
                                                playing: _vm.playing,
                                                "playback-rate":
                                                  _vm.playbackRate,
                                                "supported-playback-rates":
                                                  _vm.supportedPlaybackRates,
                                                "vod-status": _vm.vodStatus
                                              },
                                              on: {
                                                "update:currentTime": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:current-time": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:playing": function(
                                                  $event
                                                ) {
                                                  _vm.playing = $event
                                                },
                                                "update:playbackRate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:playback-rate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:supportedPlaybackRates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                },
                                                "update:supported-playback-rates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                }
                                              }
                                            })
                                          : _vm.singleRound &&
                                            _vm.singleMatch.vod_platform ===
                                              "augment" &&
                                            _vm.vodPlayer === "augment" &&
                                            _vm.singleMatch.vod_id
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: { height: "100%" }
                                              },
                                              [
                                                false
                                                  ? _c("OverwolfVideoAd", {
                                                      on: {
                                                        close: _vm.closeVideoAd,
                                                        complete:
                                                          _vm.resetAdCounters
                                                      }
                                                    })
                                                  : _c("Replay2D", {
                                                      attrs: {
                                                        "control-bar": false,
                                                        "current-time":
                                                          _vm.currentTime,
                                                        "round-replay-url":
                                                          _vm.singleMatch
                                                            .vod_id,
                                                        "offset-time":
                                                          _vm.roundOffsetTime,
                                                        playing: _vm.playing,
                                                        "playback-rate":
                                                          _vm.playbackRate,
                                                        "start-time":
                                                          _vm.selectedRoundVodStartMillis,
                                                        "end-time":
                                                          _vm.selectedRoundVodStartMillis +
                                                          _vm.singleRound
                                                            .vod_duration_millis,
                                                        "supported-playback-rates":
                                                          _vm.supportedPlaybackRates,
                                                        "vod-status":
                                                          _vm.vodStatus
                                                      },
                                                      on: {
                                                        "update:currentTime": function(
                                                          $event
                                                        ) {
                                                          _vm.currentTime = $event
                                                        },
                                                        "update:current-time": function(
                                                          $event
                                                        ) {
                                                          _vm.currentTime = $event
                                                        },
                                                        "update:playing": function(
                                                          $event
                                                        ) {
                                                          _vm.playing = $event
                                                        },
                                                        "update:playbackRate": function(
                                                          $event
                                                        ) {
                                                          _vm.playbackRate = $event
                                                        },
                                                        "update:playback-rate": function(
                                                          $event
                                                        ) {
                                                          _vm.playbackRate = $event
                                                        },
                                                        "update:supportedPlaybackRates": function(
                                                          $event
                                                        ) {
                                                          _vm.supportedPlaybackRates = $event
                                                        },
                                                        "update:supported-playback-rates": function(
                                                          $event
                                                        ) {
                                                          _vm.supportedPlaybackRates = $event
                                                        }
                                                      }
                                                    })
                                              ],
                                              1
                                            )
                                          : _vm.vodPlayer === "vod" &&
                                            _vm.selectedRound
                                          ? _c("Replay2D", {
                                              ref: "minimap",
                                              attrs: {
                                                "control-bar": false,
                                                "current-time": _vm.currentTime,
                                                "round-replay-url":
                                                  _vm.selectedRound
                                                    .vod_replay_url,
                                                "offset-time":
                                                  _vm.roundOffsetTime,
                                                playing: _vm.playing,
                                                "playback-rate":
                                                  _vm.playbackRate,
                                                "supported-playback-rates":
                                                  _vm.supportedPlaybackRates,
                                                "vod-status": _vm.vodStatus
                                              },
                                              on: {
                                                "update:currentTime": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:current-time": function(
                                                  $event
                                                ) {
                                                  _vm.currentTime = $event
                                                },
                                                "update:playing": function(
                                                  $event
                                                ) {
                                                  _vm.playing = $event
                                                },
                                                "update:playbackRate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:playback-rate": function(
                                                  $event
                                                ) {
                                                  _vm.playbackRate = $event
                                                },
                                                "update:supportedPlaybackRates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                },
                                                "update:supported-playback-rates": function(
                                                  $event
                                                ) {
                                                  _vm.supportedPlaybackRates = $event
                                                }
                                              }
                                            })
                                          : !_vm.selectedRound
                                          ? _c("div", [
                                              _vm._v("No round is selected")
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        : null,
                      {
                        key: "rounds",
                        fn: function() {
                          return [
                            !_vm.multiMatch &&
                            _vm.mapToolData &&
                            _vm.mapToolFilters
                              ? _c("MapToolRoundSelector", {
                                  attrs: {
                                    data: _vm.mapToolData,
                                    filters: _vm.mapToolFilters,
                                    selected: _vm.selected.rounds,
                                    multiple:
                                      _vm.mapMode !== "replay2d" ||
                                      _vm.vodPlayer === "playback"
                                  },
                                  on: {
                                    "update:selected": function($event) {
                                      return _vm.$set(
                                        _vm.selected,
                                        "rounds",
                                        $event
                                      )
                                    },
                                    "switch-role": _vm.switchRole
                                  }
                                })
                              : _c("div")
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }