var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-tabs" },
    [
      _c(
        "V3Button",
        {
          attrs: { active: _vm.value === "filters" },
          on: {
            click: function($event) {
              return _vm.$emit("update:value", "filters")
            }
          }
        },
        [_vm._v("Filters")]
      ),
      _c(
        "V3Button",
        {
          attrs: { active: _vm.value === "views" },
          on: {
            click: function($event) {
              return _vm.$emit("update:value", "views")
            }
          }
        },
        [_vm._v("Views")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }