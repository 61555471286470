var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-wrapper" },
    [
      _vm.fetchError
        ? _c("ErrorAlert", {
            attrs: { dismissible: "" },
            on: { dismissed: _vm.load },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [_vm._v(" " + _vm._s(_vm.fetchError) + " ")]
                  },
                  proxy: true
                },
                {
                  key: "dismiss",
                  fn: function() {
                    return [
                      _c("BIconArrowRepeat", { attrs: { title: "Retry" } })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              35515399
            )
          })
        : _vm._e(),
      _vm.permissionError
        ? _c("PermissionsAlert", {
            staticClass: "m-3",
            attrs: { message: _vm.permissionError }
          })
        : _vm._e(),
      _vm.error
        ? _c("ErrorAlert", {
            attrs: { dismissible: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [_vm._v(" " + _vm._s(_vm.error) + " ")]
                  },
                  proxy: true
                },
                {
                  key: "dismiss",
                  fn: function() {
                    return [_c("BIconXCircle", { attrs: { title: "Dismiss" } })]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3783548109
            )
          })
        : _vm._e(),
      _c("ProtoMapController", {
        attrs: {
          "can-access-playback": _vm.can_access_playback,
          "can-access-utilities": _vm.can_access_utilities,
          "parent-loading": _vm.loading > 0 || !_vm.processedMapToolData,
          "has-api-kills": true,
          "has-api-positions": true,
          "has-economy": true,
          "has-outcome": true,
          "has-plants": true,
          "has-reports": true,
          "has-wins": true,
          "create-bookmark-api": _vm.createMatchNote,
          data: _vm.processedMapToolData,
          map: _vm.mapData.id,
          notes: _vm.notes,
          "note-tags": _vm.noteTags,
          team: _vm.$route.params.team_id,
          "initial-state": _vm.initialState
        },
        on: { "update:initialState": _vm.updateInitialState }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }