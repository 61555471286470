var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-side-tabs" },
    [
      _c(
        "V3Button",
        {
          attrs: { active: _vm.value === "info" },
          on: {
            click: function($event) {
              return _vm.$emit("update:value", "info")
            }
          }
        },
        [_vm._v("Round Info")]
      ),
      _c(
        "V3Button",
        {
          attrs: { active: _vm.value === "stats" },
          on: {
            click: function($event) {
              return _vm.$emit("update:value", "stats")
            }
          }
        },
        [_vm._v("Round Stats")]
      ),
      _c(
        "V3Button",
        {
          attrs: { active: _vm.value === "xvy" },
          on: {
            click: function($event) {
              return _vm.$emit("update:value", "xvy")
            }
          }
        },
        [_vm._v("Round XvY")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }